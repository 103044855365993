import { ChevronRight } from "lucide-react"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import Footer from "../Components/Footer";
import { useContentStore } from "../Store/useContentStore";
import { MOVIE_CATEGORIES, MOVIE_LIST, TV_CATEGORIES, TV_LIST } from "../utils/constant";
import MovieSlider from "../hook/MovieSlider";
import MovieList from "../hook/MovieList";
import Navbar from "../Components/NavBar";

const AuthSCreen = () => {
	const {contentType} = useContentStore();
  	const [email, setEmail] = useState('');

  	const navigate = useNavigate();

	const handleFormSubmit = (e) => {
		e.preventDefault();
		navigate("/signup");
	};
	
  return (
    <>
    <div className="w-full h-auto lg:h-screen AuthBG">
		<Navbar/>
      {/* hero section */}
      <div className="flex flex-col items-center justify-center text-center py-40 text-white max-w-6xl font-bold mx-auto">
        <h1 className="text-2xl md:text-6xl font-bold mb-4">Unlimited Movies, Tv Shows, and Exclusive content</h1>
        <p className="text-sm md:text-xl mb-4 max-w-4xl">Experience the magic of Amerta Entertaintment. Watch, stream, and enjoy unlimited movies, Shows, and exclusive content.</p>
        <form onSubmit={handleFormSubmit}
        className="flex flex-col md:flex-row gap-2 w-1/2">
          <input
			type='email'
			placeholder='Email address'
			className='p-2 rounded flex-1 bg-black/80 border border-gray-700'
			value={email}
			onChange={(e) => setEmail(e.target.value)}
		/>
          <button className='bg-emerald-500 text-xl lg:text-2xl px-2 lg:px-6 py-1 md:py-2 rounded flex justify-center items-center'>
					Get Started
				<ChevronRight className='size-8 md:size-10' />
			</button>
        </form>

      </div>
    </div>
	{/* saparator */}

    <div className='h-2 w-full bg-[#232323]' aria-hidden='true' />

	{/* Tv Shows and movies */}
	<main>
	{contentType === "movie" ? (
        MOVIE_CATEGORIES.map((category) => {
			return (
			<MovieSlider 
			key={category} 
			category={category}/>
		)})
      ) : (
        TV_CATEGORIES.map((category) => {return(
		<MovieSlider 
		key={category} 
		category={category}/>
	)})
      )}
	</main>

    {/* mainsection */}
    <div className='py-10 bg-black text-white'>
				<div className='flex max-w-6xl mx-auto items-center justify-center md:flex-row flex-col px-4 md:px-2'>
					{/* left side */}
					<div className='flex-1 text-center md:text-left'>
						<h2 className='text-4xl md:text-5xl font-extrabold mb-4'>Free Access but Less Ads</h2>
						<p className='text-lg md:text-xl'>
            You&apos;ll have access to the full range of all platform&apos;s streaming features without ever having to pay
						</p>
					</div>
					{/* right side */}
					<div className='flex-1 relative'>
						<img src='/tv.png' alt='Tv image' className='mt-4 z-20 relative' />
						<video
							className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-1/2 z-10'
							playsInline
							autoPlay={true}
							muted
							loop
						>
							<source src='/hero-vid.m4v' type='video/mp4' />
						</video>
					</div>
				</div>
			</div>

			{/* separator */}
			<div className='h-2 w-full bg-[#232323]' aria-hidden='true' />

			{/* content */}
			<main>
			{contentType === "movie" ? (
				MOVIE_LIST.map((category) => {
			return (
			<MovieList 
			key={category} 
			category={category}/>
		)})
      	) : (
        		TV_LIST.map((category) => {return(
			<MovieList 
			key={category} 
			category={category}/>
		)})
      	)}
			</main>

{/* 			
			<div className='py-10 bg-black text-white'>
				<div className='flex max-w-6xl mx-auto items-center justify-center md:flex-row flex-col-reverse px-4 md:px-2'>
					
					<div className='flex-1 relative'>
						<div className='relative'>
							<img src='/stranger-things-lg.png' alt='Stranger Things img' className='mt-4' />

							<div
								className='flex items-center gap-2 absolute bottom-5 left-1/2 -translate-x-1/2 bg-black
              w-3/4 lg:w-1/2 h-24 border border-slate-500 rounded-md px-2
              '
							>
								<img src='/stranger-things-sm.png' alt='image' className='h-full' />
								<div className=' flex justify-between items-center w-full'>
									<div className='flex flex-col gap-0'>
										<span className='text-md lg:text-lg font-bold'>Stranger Things</span>
										<span className='text-sm text-blue-500'>Downloading...</span>
									</div>

									<img src='/download-icon.gif' alt='' className='h-12' />
								</div>
							</div>
						</div>
					</div>

					<div className='flex-1 md:text-left text-center'>
						<h2 className='text-4xl md:text-5xl font-extrabold mb-4 text-balance'>
              			Great Value, Low Price
						</h2>
						<p className='text-lg md:text-xl'>
             			 Quality entertainment doesn&apos;t have to be expensive. Our plans are designed to give you everything you want at a price you&apos;ll love.
						</p>

					</div>
				</div>
			</div> */}

			{/* separator */}

			
			{/* 3rd section */}
			<div className='py-10 bg-black text-white'>
				<div className='flex max-w-6xl mx-auto items-center justify-center md:flex-row flex-col px-4 md:px-2'>
					{/* left side */}
					<div className='flex-1 text-center md:text-left'>
						<h2 className='text-4xl md:text-5xl font-extrabold mb-4'>
							Watch everywhere
						</h2>
						<p className='text-lg md:text-xl'>
            				You can stream on any device with an internet connection. Watch movies, shows, and videos at your convenience, without missing a beat.
						</p>
					</div>

					{/* right side */}
					<div className='flex-1 relative overflow-hidden'>
						<img src='/device-pile.png' alt='Device image' className='mt-4 z-20 relative' />
						<video
							className='absolute top-2 left-1/2 -translate-x-1/2  h-4/6 z-10
               max-w-[63%] 
              '
							playsInline
							autoPlay={true}
							muted
							loop
						>
							<source src='/video-devices.m4v' type='video/mp4' />
						</video>
					</div>
				</div>
			</div>

			<div className='h-2 w-full bg-[#232323]' aria-hidden='true' />

			{/* 4th section*/}
			<div className='py-10 bg-black text-white'>
				<div
					className='flex max-w-6xl mx-auto items-center justify-center flex-col-reverse md:flex-row
           px-4 md:px-2
        '
				>
					{/* right */}
					<div className='flex-1 text-center'>
						<h2 className='text-4xl md:text-5xl font-extrabold mb-4'>Exclusive Content Coming Soon</h2>
						<p className='text-lg md:text-xl'>
            				Get ready for something special! We&apos;re excited to announce that exclusive content is on its way to Amerta Entertaintment. Soon, you&apos;ll be able to enjoy a whole new selection of shows, movies, and videos you won&apos;t find anywhere else.
						</p>
            <h2 className="text-4xl md:text-5xl font-extrabold mb-4 mt-12 md:mt-20">
              What&apos;s Coming?
            </h2>
            <p className="text-lg md:text-xl">
            We&apos;re bringing you fresh, original content created just for you. Whether it&apos;s new series, behind-the-scenes footage, or special features, our exclusive content will take your experience to the next level.
            </p>
					</div>

				</div>
			</div>
      
      <div className='h-2 w-full bg-[#232323]' aria-hidden='true' />
      
      <div className='mt-32 flex-1 text-center mb-5 h-screen flex flex-col justify-center items-center'>
		<h2 className='text-4xl md:text-8xl font-extrabold mb-4'>Exclusive Content</h2>
        <h2 className='text-4xl md:text-8xl font-extrabold bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text'>November-20-2025</h2>
	  </div>
      {/* <Footer/> */}
    </>
  )
}

export default AuthSCreen