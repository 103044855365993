import AuthSCreen from "../Main/AuthSCreen";
import HomeScreen from "../Main/HomeScreen";
import { useAuthStore } from "../Store/useAuthStore"; // Uncomment this line

const Home = () => {
  const { isAuthenticated } = useAuthStore();
  const user =isAuthenticated; // Use the authentication state from the store

  return (
    <div>
      {user ? <HomeScreen /> : <AuthSCreen />} {/* Use isAuthenticated */}
    </div>
  );
}

export default Home;