import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Input from "../Components/Input";
import { LoaderCircle, Lock, Mail } from "lucide-react";
import { useState } from "react";
import { useAuthStore } from "../Store/useAuthStore";
import toast from "react-hot-toast";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, error, isLoading } = useAuthStore();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate("/");
      toast.success("login success");
    } catch (error) {
      console.error("Verification error:", error);
      toast.error("Invalid verification code");
      
    }
  };

  return (
    <div className="w-full h-screen AuthBG">
      <header className="max-w-6xl mx-auto flex flex-wrap items-center justify-between p-4 h-20 text-emerald-400">
        <Link to='/'>
					<div>
						<h1 className="font-bold text-xl md:text-3xl">
							Dopee
						</h1>
					</div>
				</Link>
      </header>

      {/* Login section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="flex justify-center flex-col items-center mt-10 mx-4"
      >
        <div className="h-auto w-full lg:w-1/3 bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border border-gray-100 p-4">
          <h1 className="text-2xl font-bold text-center mb-5 text-slate-200">
            Login to Your Account
          </h1>
          <form onSubmit={handleLogin}>
            <Input
              icon={Mail}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input
              icon={Lock}
              type="password"
              placeholder="Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Link to="/forgot-password" className="text-emerald-400 hover:text-slate-200 hover:underline mb-5">
              Forgot password?
            </Link>
            {error && <p className='text-red-500 font-semibold mt-2'>{error}</p>}
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              disabled={isLoading}
              className="mt-5 w-full py-3 px-4 bg-gradient-to-r from-green-500 to-emerald-600 text-white 
                font-bold rounded-lg shadow-lg hover:from-green-600
                hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2
                focus:ring-offset-gray-900 transition duration-200"
            >
              {isLoading ? <LoaderCircle className="size-6 animate-spin text-center mx-auto" /> : "Login"}
            </motion.button>
          </form>

          <div className="pt-5">
            <h2 className="font-semibold text-base text-emerald-400">
              Don&apos;t have an account?{" "}
              <Link to="/signup" className="text-slate-200 hover:text-blue-400 hover:underline">
                Sign Up
              </Link>
            </h2>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;