import axios from "axios";
import { create } from "zustand";

const API_URL = "http://localhost:8080/api/auth";
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const useAuthStore = create((set) => ({
  user: null,
  isAuthenticated: false,
  error: null,
  isLoading: false,
  isCheckingAuth: true,

  setError: (error) => set({ error }),
  setLoading: (isLoading) => set({ isLoading }),
  setUser: (user) => set({ user, isAuthenticated: !!user }),

  signup: async (email, password, username) => {
    set({ isLoading: true, error: null });
    try {
      const { data } = await axiosInstance.post("/signup", { email, password, username });
      set({ user: data.user, isAuthenticated: true, isLoading: false });
    } catch (error) {
      set({ error: error.response?.data?.message || "Error signing up", isLoading: false });
      throw error;
    }
  },

  verifyEmail: async (code) => {
    set({ isLoading: true, error: null });
    try {
      const { data } = await axiosInstance.post("/verify-email", { code });
      set({ user: data.user, isAuthenticated: true, isLoading: false });
      return data;
    } catch (error) {
      set({ error: error.response?.data?.message || "Error verifying email", isLoading: false });
      throw error;
    }
  },

  checkAuth: async () => {
    set({ isCheckingAuth: true, error: null });
    try {
      const { data } = await axiosInstance.get("/check-auth");
      set({ user: data.user, isAuthenticated: true, isCheckingAuth: false });
    } catch (error) {
      set({ user: null, isAuthenticated: false, isCheckingAuth: false });
    }
  },

  login: async (email, password) => {
    set({ isLoading: true, error: null });
    try {
      const { data } = await axiosInstance.post("/login", { email, password });
      set({ user: data.user, isAuthenticated: true, isLoading: false });
    } catch (error) {
      set({ error: error.response?.data?.message || "Error logging in", isLoading: false });
      throw error;
    }
  },

  logout: async () => {
    set({ isLoading: true, error: null });
    try {
      await axiosInstance.post("/logout");
      set({ user: null, isAuthenticated: false, isLoading: false });
    } catch (error) {
      set({ error: error.response?.data?.message || "Error logging out", isLoading: false });
      throw error;
    }
  },
  Forgotpassword: async (email) => {
    set({isLoading: true, error: null});
    try {
        const response = await axios.post(`${API_URL}/forgot-password`, {email});
        set({message: response.data.message, isLoading: false})
    } catch (error) {
        set({error: error.response?.data?.message || "Error sending password reset link", isLoading: false});
        throw error;
    }
  },
  resetpassword: async (token, password) => {
    set({isLoading: true, error: null});
    try {
        const response = await axios.post(`${API_URL}/reset-password`, {token, password});
        set({message: response.data.message, isLoading: false})
    } catch (error) {
        set({error: error.response?.data?.message || "Error resetting password", isLoading: false});
        throw error;
    }
  }
}));