import { useState } from "react";
import NavBar from "../Components/NavBar";
import { IMAGE_URL, MOVIE_CATEGORIES, MOVIE_LIST, TV_CATEGORIES, TV_LIST } from "../utils/constant";
import useTrendingContent from "../hook/useTrending";
import { Link, useNavigate } from "react-router-dom";
import { Info, Play, Loader2 } from "lucide-react";
import { useContentStore } from "../Store/useContentStore";
import MovieSlider from "../hook/MovieSlider";
import MovieList from "../hook/MovieList";

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
    <Loader2 className="animate-spin text-emerald-400 w-16 h-16" />
  </div>
);

const HomeScreen = () => {
  const { trendingContent, isLoading } = useTrendingContent();
  const { contentType } = useContentStore();
  const [imgLoading, setImgLoading] = useState(true);
  const navigate = useNavigate();

  const handlePlay = async (e) => {
    e.preventDefault();
    try {
      if (contentType === "movie") {
        return navigate(`/watch-now/${trendingContent?.id}/play/${trendingContent?.title}`);
      }
      navigate(`/watch-now/${trendingContent?.id}/play/${trendingContent?.title}/season/1/episode/1`);
    } catch (error) {
      console.error(error);
    }
  };

  // Show loading spinner if content is loading
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {/* Hero Section */}
      <div className="relative h-screen text-emerald-400">
        <NavBar />
        
        {/* Image Loading Overlay */}
        {imgLoading && (
          <div className="absolute inset-0 bg-black/80 flex items-center justify-center z-10">
            <Loader2 className="animate-spin text-emerald-400 w-12 h-12" />
          </div>
        )}

        {/* Background Image */}
        <img
          src={IMAGE_URL + trendingContent?.backdrop_path}
          onLoad={() => setImgLoading(false)}
          alt={trendingContent?.title || trendingContent?.name}
          className="absolute inset-0 w-full h-full object-cover -z-50"
        />

        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black/50 -z-40" />

        {/* Content Details */}
        <div className="absolute inset-0 flex flex-col justify-center px-8 md:px-16 lg:px-32">
          <div className="max-w-xl space-y-4">
            <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold">
              {trendingContent?.title || trendingContent?.name}
            </h1>
            
            <div className="text-sm md:text-base space-y-2">
              <p>
                {trendingContent?.first_air_date?.split('-')[0] || trendingContent?.release_date?.split('-')[0]} 
                {" | "}
                {trendingContent?.adult ? "18+" : "PG-13"}
              </p>
              
              <p className="line-clamp-3">
                {trendingContent?.overview}
              </p>
            </div>

            <div className="flex space-x-4">
              <Link 
                onClick={handlePlay}
                className="bg-white/50 hover:bg-emerald-500 text-black hover:text-white transition-colors px-4 py-2 rounded-md flex items-center space-x-2"
              >
                <Play />
                <span>Play</span>
              </Link>
              
              <Link 
                to={`watch/${trendingContent?.id}`}
                className="bg-white/50 hover:bg-emerald-500 text-black hover:text-white transition-colors px-4 py-2 rounded-md flex items-center space-x-2"
              >
                <Info />
                <span>More Info</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Movie/TV Categories */}
      <div className="bg-black py-10 space-y-10">
        <section>
          {contentType === "movie"
            ? MOVIE_CATEGORIES.map((category) => (
                <MovieSlider key={category} category={category} />
              ))
            : TV_CATEGORIES.map((category) => (
                <MovieSlider key={category} category={category} />
              ))}
        </section>

        <main>
          {contentType === "movie"
            ? MOVIE_LIST.map((category) => (
                <MovieList key={category} category={category} />
              ))
            : TV_LIST.map((category) => (
                <MovieList key={category} category={category} />
              ))}
        </main>
      </div>
    </div>
  );
};

export default HomeScreen;