import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {useAuthStore} from "../Store/useAuthStore"
import toast from 'react-hot-toast';

const EmailVerify = () => {
    const [code, setCode] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const { isLoader, verifyEmail } = useAuthStore();

    const handleChange = (index, value) => {
        const newCode = [...code];
        newCode[index] = value.replace(/[^0-9]/g, '');
        setCode(newCode);

        if (value !== '' && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && code[index] === '') {
            inputRefs.current[index - 1].focus();
        }
    };

    useEffect(() => {
        if (code.every(digit => digit.length > 0)) {
            handleSubmit(new Event('submit'));
        }
    }, [code]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const verificationToken = code.join("");
        try {
            await verifyEmail(verificationToken);
            navigate("/");
            toast.success("Email verified");
        } catch (error) {
            console.error("Verification error:", error);
            toast.error("Invalid verification code");
        }
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } }
    };

    const inputVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: { scale: 1, opacity: 1, transition: { duration: 0.5 } }
    };

    return (
        <div className="w-full h-screen AuthBG">
            <header className="w-full flex items-center px-12 pt-10 lg:pt-5">
                <div>
                    <h1 className="text-emerald-500 text-2xl font-semibold">
                        Amerta Entertainment
                    </h1>
                </div>
            </header>
            <div className="min-h-screen w-full AuthBG overflow-hidden flex items-center justify-center px-4">
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="w-full max-w-md">
                    <div className="bg-gray-400 rounded-lg bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border border-gray-100 p-8">
                        <h1 className="text-3xl font-bold text-center mb-6 text-slate-200">
                            Verify your email
                        </h1>
                        <p className="text-emerald-500 text-center font-medium mb-8">
                            Enter the 6-digit code sent to your email
                        </p>
                        <form onSubmit={handleSubmit} className="space-y-8">
                            <div className="flex justify-between">
                                {code.map((digit, index) => (
                                    <motion.input
                                        key={index}
                                        variants={inputVariants}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        type="text"
                                        maxLength='1'
                                        value={digit}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        className="w-12 h-14 text-center text-2xl text-white bg-transparent border-2 border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary mx-1"
                                    />
                                ))}
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                type="submit"
                                disabled={isLoader}
                                className="w-full py-3 bg-emerald-500 text-white font-semibold rounded-md shadow-md hover:bg-emerald-600 transition duration-300 ease-in-out">
                                {isLoader ?
                                    <motion.div
                                        animate={{ rotate: 360 }}
                                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                        className="w-6 h-6 border-t-2 border-white rounded-full mx-auto"
                                    /> :
                                    "Verify"
                                }
                            </motion.button>
                        </form>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default EmailVerify;