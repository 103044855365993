import { Route, Routes } from "react-router-dom"
import Login from "./Pages/Login"
import Home from "./Pages/Home"
import Signup from "./Pages/Signup"
import ErrorPage from "./ErrorPages/ErrorPage"
import EmailVerify from "./Pages/verify-emily"
import {Toaster} from "react-hot-toast";
import { useAuthStore } from "./Store/useAuthStore"
import { useEffect } from "react"
import ForgotPassword from "./Pages/ForgotPass"
import WatchPage from "./Pages/WatchPage"
import SearchPage from "./Pages/SearchPage"
import SearchHistory from "./Pages/SearchHistoryPage"
import PlayMovie from "./playStream/PlayMovie"
import PlayTv from "./playStream/PlayTv"

function App() {

  const { checkAuth } = useAuthStore();

  useEffect(()=>{
    checkAuth()
  },[checkAuth])
  
  return (

    <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/watch/:id" element={<WatchPage/>} />
      <Route path="/watch-now/:id/play/:title" element={<PlayMovie/>} />
      <Route path="/watch-now/:id/play/:title/season/:season/episode/:eps" element={<PlayTv/>} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/history" element={<SearchHistory />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify-email" element={<EmailVerify />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
    <Toaster />
    </>
  )
}

export default App

