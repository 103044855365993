import { motion } from "framer-motion";
import Input from "../Components/Input";
import { useState } from "react";
import { Loader, Lock, Mail, User } from "lucide-react";
import PassStrength from "../Components/PassStrength";
import { useAuthStore } from "../Store/useAuthStore";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Signup = () => {
    const [formData, setFormData] = useState({ name: "", email: "", password: "" });
    const navigate = useNavigate();
    const { signup, error, isLoading } = useAuthStore();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        const { name, email, password } = formData;

        if (!name || !email || !password) {
            toast.error("All fields are required!");
            return;
        }

        try {
            await signup(email, password, name);
            navigate("/verify-email");
        } catch (err) {
            console.error("Signup error:", err);
            toast.error(err.response?.data?.message || "Signup failed");
        }
    };

    return (
        <div className="w-full h-screen overflow-hidden AuthBG">
            <header className="max-w-6xl mx-auto flex flex-wrap items-center justify-between p-4 h-20 text-emerald-400">
                <Link to='/'>
					<div>
						<h1 className="font-bold text-xl md:text-3xl">
							Dopee
						</h1>
					</div>
				</Link>
            </header>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.3 }}
                className="flex flex-col items-center h-full"
            >
                <form onSubmit={handleSignUp} className="h-auto w-full lg:w-1/3 bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border border-gray-100 p-4">
                    <h2 className="text-2xl font-bold text-center mb-5 text-slate-200">Create Your Account</h2>
                    <Input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        icon={User}
                        required
                    />
                    <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        icon={Mail}
                        required
                    />
                    <Input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        icon={Lock}
                        required
                    />
                    <PassStrength password={formData.password} />
                    <button
                        type="submit"
                        className={`w-full bg-emerald-500 text-white py-2 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                    >
                        {isLoading ? <Loader className="size-6 animate-spin text-center mx-auto" /> : "Sign Up"}
                    </button>
                    {error && <p className="text-red-500 mt-2">{error}</p>}
                    <p className="mt-4 text-center text-slate-200">
                        Already have an account? <Link to="/login" className="text-emerald-500">Log in</Link>
                    </p>
                </form>
            </motion.div>
        </div>
    );
};

export default Signup;