import { useEffect, useState } from "react";
import { useContentStore } from "../Store/useContentStore";
import axios from "axios";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../utils/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import { Navigation } from "swiper/modules";

const MovieSlider = ({ category }) => {
    const { contentType } = useContentStore();
    const [content, setContent] = useState([]);

    const formattedCategoryName =
        category.replaceAll("_", " ")[0].toUpperCase() + category.replaceAll("_", " ").slice(1);
    const formattedContentType = contentType === "movie" ? "Movies" : "TV Shows";

    useEffect(() => {
        const getContent = async () => {
            try {
                const res = await axios.get(`/api/${contentType}/${category}`);
                const fetchedContent = Array.isArray(res.data.content) 
                    ? res.data.content 
                    : (res.data.content ? [res.data.content] : []);
                setContent(fetchedContent);
            } catch (error) {
                console.error("Error fetching content:", error);
                setContent([]);
            }
        };

        getContent();
    }, [contentType, category]);

    return (
        <div className='bg-black text-white relative px-4 sm:px-8 md:px-16 lg:px-20 py-10'>
            <h2 className='mb-4 text-xl sm:text-2xl md:text-3xl font-bold'>
                {formattedCategoryName} {formattedContentType}
            </h2>

            <Swiper
                modules={[Navigation]}
                navigation
                spaceBetween={20}
                slidesPerView={3} // Adjust the number of slides per view as needed
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                }}
                className='pb-4'
            >
                {content.map((item) => (
                    <SwiperSlide key={item.id} className='flex-shrink-0'>
                        <Link to={`/watch/${item.id}`} className='block'>
                            <div className='rounded-lg overflow-hidden shadow-lg'>
                                <img
                                    src={IMAGE_URL + item.poster_path}
                                    alt={item.title || item.name}
                                    className='w-full h-auto object-cover'
                                />
                            </div>
                            <p className='mt-2 text-base lg:text-lg truncate font-semibold'>
                                {item.title || item.name}
                            </p>
                            <p className='mt-2 text-base lg:text-lg truncate font-semibold'>
                                {item.first_air_date?.split('-')[0] || item.release_date?.split('-')[0]}
                            </p>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default MovieSlider;