import { Check, X } from "lucide-react";
import PropTypes from 'prop-types';

const PassCriteria = ({ password }) => {
    const criteria = [
        { label: 'At least 6 Characters', met: password.length >= 6 },
        { label: 'Contains uppercase letter', met: /[A-Z]/.test(password) },
        { label: 'Contains lowercase letter', met: /[a-z]/.test(password) },
        { label: 'Contain a number', met: /\d/.test(password) },
        { label: 'Contain special character', met: /[^A-Za-z0-9]/.test(password) },
    ];

    return (
        <div className="mt-2 space-y-1">
            {criteria.map((item) => (
                <div key={item.label} className="flex items-center text-sm">
                    {item.met ? (
                        <Check className="size-4 text-green-500 mr-2"/>
                    ) : (
                        <X className="size-4 text-red-500 mr-2"/>
                    )}
                    <span className={item.met ? 'text-green-500' : 'text-red-500'}>
                        {item.label}
                    </span>
                </div>
            ))}
        </div>
    );
};

PassCriteria.propTypes = {
    password: PropTypes.string.isRequired,
};

const PassStrength = ({ password }) => {
    const getStrength = (pass) => {
        let strength = 0;
        if (pass.length >= 8) strength++;
        if (pass.match(/[A-Z]/) && pass.match(/[a-z]/)) strength++;
        if (pass.match(/\d/)) strength++;
        if (pass.match(/[^A-Za-z0-9]/)) strength++;
        return strength;
    };
    const strength = getStrength(password);

    const getColor = (strength) => {
        if (strength === 0) return "bg-red-500";
        if (strength === 1) return "bg-yellow-400";
        if (strength === 2) return "bg-yellow-500";
        if (strength === 3) return "bg-green-500";
        return "bg-green-600";
    };

    const getStrengthText = (strength) => {
        if (strength === 0) return "Very Weak";
        if (strength === 1) return "Weak";
        if (strength === 2) return "Good";
        if (strength === 3) return "Strong";
        return "Very Strong";
    };

    return (
        <div className="mt-2">
            <div className="flex justify-between items-center mb-1">
                <span className="text-sm text-slate-300">
                    Password Strength
                </span>
                <span className="text-sm text-gray-400 ml-2">
                    {getStrengthText(strength)}
                </span>
            </div>
            <div className="flex space-x-1">
                {[...Array(4)].map((_, index) => (
                    <div
                        key={index}
                        className={`h-1 w-1/4 rounded-full transition-colors duration-300 ${
                            index < strength ? getColor(strength) : 'bg-red-500'
                        }`}
                    ></div>
                ))}
            </div>
            <PassCriteria password={password} />
        </div>
    );
};

PassStrength.propTypes = {
    password: PropTypes.string.isRequired,
};

export default PassStrength;